<template>
  <page-container :title="pageTitle" :breadcrumb="breadcrumb">
    <div class="box-container" style="margin-top: 3px;">
      <div class="box-container-inner">
        <a-tabs size="small" @change="callback">
          <a-tab-pane key="tab2" tab="同一编号/位置异常情况分析表">
            <div class="box-container">
              <div class="box-container-inner">
                <div class="simple-query">
                  <a-form-model ref="queryForm" :model="queryParams1" layout="inline" class="query-form">
                    <a-form-model-item label="报警/异常" prop="eventtype" style="width: 28%">
                      <a-select v-model="queryParams1.eventtype">
                        <a-select-option :value="901">报警</a-select-option>
                        <a-select-option :value="902">异常</a-select-option>
                      </a-select>
                    </a-form-model-item>
                    <a-form-model-item label="公司/项目" prop="monitorpointname" style="width: 28%">
                      <a-cascader :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="userInfo.usertype!='0'?deptOptions.filter(item => item.deptype=='1'):deptOptionsAll.filter(item => item.deptype=='1')" v-model="userdepidCascaderSelected1" :load-data="userInfo.usertype!='0'?deptOnLoadChildren:deptOnLoadChildrenAll" placeholder="请选择公司/项目" :change-on-select="true" :allow-clear="false"></a-cascader>
                    </a-form-model-item>
                    <a-form-model-item label="时段" prop="queryInstallDate1" style="width: 28%">
                      <a-range-picker v-model="queryInstallDate1" :default-value="[moment(`${queryParams1.starttime.substring(0,10)}`, 'YYYY-MM-DD'), moment(`${queryParams1.endtime.substring(0,10)}`, 'YYYY-MM-DD')]"></a-range-picker>
                    </a-form-model-item>
                    <div class="query-btns" style="float: right;width: 16%">
                      <a-button @click="resetQueryParams">重置</a-button>
                      <a-button type="primary" class="after-btn" @click="getTableData(true,activeKey)">查询</a-button>
<!--                      <a-button type="link" @click="showAdvanced1=!showAdvanced1">展开<a-icon :type="showAdvanced?'up':'down'"></a-icon></a-button>-->
                    </div>
                    <div v-show="showAdvanced1" class="advanced-query">
                      <a-form-model-item v-if="queryParams1.eventtype===901" label="系统名称(场景)" prop="systemnameid" style="width: 28%">
                        <a-select v-model="queryParams1.systemnameid">
                          <a-select-option value="">全部</a-select-option>
                          <a-select-option v-for="(item, index) in systemNames" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
                        </a-select>
                      </a-form-model-item>
                      <a-form-model-item label="报警/事件类型" prop="eventcode" style="width: 28%">
                        <a-select v-model="queryParams1.eventcode">
                          <a-select-option value="">全部</a-select-option>
                          <a-select-option v-for="(item, index) in Types" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
                        </a-select>
                      </a-form-model-item>
                      <a-form-model-item v-if="queryParams1.eventtype===901&&queryParams1.systemnameid==='1'" label="原因分类" prop="causeclassify" style="width: 28%">
                        <a-select v-model="queryParams1.causeclassify" placeholder="">
                          <a-select-option value="">全部</a-select-option>
                          <a-select-option v-for="(item, index) in causeClassifyList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
                        </a-select>
                      </a-form-model-item>
<!--                      <a-form-model-item  label="设备类型" prop="devicecode" style="width: 28%">-->
<!--                        <a-select v-model="queryParams1.devicecode">-->
<!--                          <a-select-option value="">全部</a-select-option>-->
<!--                          <a-select-option value="01">强电</a-select-option>-->
<!--                          <a-select-option value="02">弱电</a-select-option>-->
<!--                          <a-select-option value="03">水系统</a-select-option>-->
<!--                          <a-select-option value="04">暖通</a-select-option>-->
<!--                          <a-select-option value="05">气体</a-select-option>-->
<!--                          <a-select-option value="06">运输</a-select-option>-->
<!--                          <a-select-option value="07">车库/道路</a-select-option>-->
<!--                          <a-select-option value="08">空间</a-select-option>-->
<!--                          <a-select-option value="09">其它</a-select-option>-->
<!--                        </a-select>-->
<!--                      </a-form-model-item>-->
                      <a-form-model-item v-if="queryParams1.eventtype===902&&queryParams1.eventcode==='6'" label="设备类型" prop="devicetype" style="width: 28%">
                        <a-cascader :field-names="{ label: 'devicename', value: 'devicecode', children: 'children' }" :options="deviceOptions" v-model="deviceCascaderSelected" :load-data="deviceOnLoadChildren" placeholder="请选择设备类型" :change-on-select="true"></a-cascader>
                      </a-form-model-item>
                      <a-form-model-item label="计划性作业" prop="plannedwork" style="width: 28%">
                        <a-select v-model="queryParams1.plannedwork">
                          <a-select-option value="">全部</a-select-option>
                          <a-select-option :value="0">否</a-select-option>
                          <a-select-option :value="1">是</a-select-option>
                        </a-select>
                      </a-form-model-item>
                      <a-form-model-item label="同一编号/位置异常次数" prop="total" style="width: 28%">
                        <a-select v-model="queryParams1.total">
                          <a-select-option value="">全部</a-select-option>
                          <a-select-option value="2">两次</a-select-option>
                          <a-select-option value="3">三次</a-select-option>
                          <a-select-option value="4">三次以上</a-select-option>
                        </a-select>
                      </a-form-model-item>
                    </div>
                  </a-form-model>
                </div>
              </div>
            </div>
            <div class="box-container">
              <div class="box-container-inner">
                <div class="table-header">
                  <h3 class="table-title">同一编号/位置异常情况分析表</h3>
                  <div class="table-btns">
                    <a-button @click="exportReportModal" style="margin-right: 10px"><a-icon type="export"></a-icon>批量导出</a-button>
                    <a-icon type="redo" class="after-btn table-refresh" @click="getTableData()"></a-icon>
                  </div>
                </div>
                <a-table :columns="tableColumns" :data-source="tableData1" :rowKey="(record,index)=> index" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
                <span slot="arrivetime" slot-scope="value">
                  <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
                    <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
                  </a-tooltip>
                </span>
                  <span slot="eventtype" slot-scope="value,record">{{value===901?"报警":"异常"}}</span>
                  <span slot="num" slot-scope="value,record">
                  <a-button type="link" @click="checkLocationRecordDetail(record)">{{value?value:""}}</a-button>
                </span>
                  <span slot="operation" slot-scope="value, record">
                  <a-dropdown>
                    <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                      <a-icon type="menu"/>
                    </a>
                    <a-menu slot="overlay" @click="operationClick(record)">
                      <a-menu-item :key="'detail'">详情</a-menu-item>
                    </a-menu>
                  </a-dropdown>
                </span>
                </a-table>
                <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
                  <template slot="buildOptionText" slot-scope="props">
                    <span>{{ props.value }}条/页</span>
                  </template>
                </a-pagination>
              </div>
            </div>
          </a-tab-pane>
          <a-tab-pane key="tab1" tab="异常情况分析表">
            <div class="box-container">
              <div class="box-container-inner">
              <div class="simple-query">
                <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
                  <a-form-model-item label="报告" prop="reporttype" style="width: 25%">
                    <a-select v-model="queryParams.reporttype">
                      <a-select-option :value="1">故障</a-select-option>
                      <a-select-option :value="2">报警</a-select-option>
                    </a-select>
                  </a-form-model-item>
                  <a-form-model-item label="公司/项目" prop="monitorpointname" style="width: 25%">
                    <a-cascader :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="userInfo.usertype!='0'?deptOptions.filter(item => item.deptype=='1'):deptOptionsAll.filter(item => item.deptype=='1')" v-model="userdepidCascaderSelected" :load-data="userInfo.usertype!='0'?deptOnLoadChildren:deptOnLoadChildrenAll" placeholder="请选择公司/项目" :change-on-select="true" :allow-clear="false"></a-cascader>
                  </a-form-model-item>
                  <a-form-model-item label="时段" prop="queryInstallDate" style="width: 25%">
                    <a-range-picker v-model="queryInstallDate" :default-value="[moment(`${queryParams.starttime.substring(0,10)}`, 'YYYY-MM-DD'), moment(`${queryParams.endtime.substring(0,10)}`, 'YYYY-MM-DD')]"></a-range-picker>
                  </a-form-model-item>
                  <div class="query-btns" style="float: right;width: 20%">
                    <a-button @click="resetQueryParams">重置</a-button>
                    <a-button type="primary" class="after-btn" @click="getTableData(true,activeKey)">查询</a-button>
                    <a-button type="link" @click="showAdvanced=!showAdvanced">展开<a-icon :type="showAdvanced?'up':'down'"></a-icon></a-button>
                  </div>
                  <div v-show="showAdvanced" class="advanced-query">
                    <a-form-model-item label="系统" prop="systemnameid" style="width: 25%">
                      <a-select v-model="queryParams.systemnameid">
                        <a-select-option value="">全部</a-select-option>
                        <a-select-option v-for="(item, index) in systemNames" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
                      </a-select>
                    </a-form-model-item>
                    <a-form-model-item label="报警类型" prop="eventcode" style="width: 25%">
                      <a-select v-model="queryParams.eventcode">
                        <a-select-option value="">全部</a-select-option>
                        <a-select-option v-for="(item, index) in alarmTypes" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
                      </a-select>
                    </a-form-model-item>
                    <a-form-model-item label="设备类型" prop="devicetype" style="width: 25%">
                      <a-cascader :field-names="{ label: 'devicename', value: 'devicecode', children: 'children' }" :options="deviceOptions" v-model="deviceCascaderSelected" :load-data="deviceOnLoadChildren" placeholder="请选择设备类型" :change-on-select="true"></a-cascader>
                    </a-form-model-item>
                    <a-form-model-item label="编号/位置" prop="location" style="width: 25%">
                      <a-input v-model="queryParams.location" placeholder="请输入准确的编号/位置"></a-input>
                    </a-form-model-item>
                    <a-form-model-item label="到场反馈" prop="feedback" style="width: 25%">
                      <a-select v-model="queryParams.feedback">
                        <a-select-option value="">全部</a-select-option>
                        <a-select-option v-for="(item, index) in feedbackList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
                      </a-select>
                    </a-form-model-item>
                    <a-form-model-item label="原因分类" prop="causeclassify" style="width: 25%">
                      <a-select v-model="queryParams.causeclassify" placeholder="">
                        <a-select-option value="">全部</a-select-option>
                        <a-select-option v-for="(item, index) in causeClassifyList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
                      </a-select>
                    </a-form-model-item>
                  </div>
                </a-form-model>
              </div>
              </div>
            </div>
            <div class="box-container">
            <div class="box-container-inner">
              <div class="table-header">
                <h3 class="table-title">{{pageTitle}}</h3>
                <div class="table-btns">
                  <a-button @click="exportModal" style="margin-right: 10px"><a-icon type="export"></a-icon>批量导出</a-button>
                  <a-icon type="redo" class="after-btn table-refresh" @click="getTableData()"></a-icon>
                </div>
              </div>
              <a-table :columns="tableColumns" :data-source="tableData" :rowKey="(record,index)=> index" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
                <span slot="arrivetime" slot-scope="value">
                  <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
                    <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
                  </a-tooltip>
                </span>
                <span slot="timeaverage" slot-scope="value,record">
                  <span>{{value?value:""}}</span>
                </span>
                <span slot="total" slot-scope="value,record">
                  <a-button type="link" @click="checkTotalRecordDetail(record)">{{value?value:""}}</a-button>
                </span>
                <span slot="minaverage" slot-scope="value,record">
                  <a-button type="link" @click="checkMinAverageDetail(record)">{{value?value:""}}</a-button>
                </span>
                <span slot="operation" slot-scope="value, record">
                  <a-dropdown>
                    <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                      <a-icon type="menu"/>
                    </a>
                    <a-menu slot="overlay" @click="operationClick(record)">
                      <a-menu-item :key="'detail'">详情</a-menu-item>
                    </a-menu>
                  </a-dropdown>
                </span>
              </a-table>
              <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
                <template slot="buildOptionText" slot-scope="props">
                  <span>{{ props.value }}条/页</span>
                </template>
              </a-pagination>
              </div>
            </div>
          </a-tab-pane>
      </a-tabs>
      </div>
    </div>
    <event-record-detail-list :visible.sync="detailVisible" :modalDetail="modalDetail"></event-record-detail-list>
  </page-container>
</template>
<script>
import moment from "moment";
import {mapState} from "vuex";
import {getCache, getTimeRange} from "U/index";
import {
  getAbnormalAnalysisReport,
  findAbnormalAnalysisReportGroupLocation,
  exportAbnormalAnalysisReportGroupLocation
} from "A/wlw";
import deptselect from "@/mixins/deptselect";
import {exportEventRecordReportExcel} from "A/jcgn";
import deviceselect from "@/mixins/deviceselect";
import EventRecordDetailList from "C/modals/EventRecordDetailList";

export default {
  name: "analysisReport",
  components: {EventRecordDetailList},
  mixins: [deviceselect,deptselect],
  data() {
    return {
      moment,
      detailVisible:false,
      modalDetail:{},
      checkRecordsVisible:false,
      userdepidCascaderSelected: [],
      deviceCascaderSelected: [],
      systemNamesMap:{},
      systemNames:[],
      alarmTypes:[],
      abnormalTypes:[],
      feedbackList:[],
      causeClassifyList:[],
      tableData: [],
      tableData1: [],
      Types:[],
      tableLoading: false,
      showAdvanced: false,
      showAdvanced1: true,
      queryInstallDate:null,
      queryParams:{
        monitorpointname:'',
        systemnameid:'',
        reporttype:1,
        starttime:'',
        endtime:'',
        eventcode:'',
        location:'',
        feedback:'',
        plannedwork:'',
        causeclassify:'',
        userdepsid:143,
        devicecode:'',
        querytype:1,
        total:'',
      },
      queryInstallDate1:null,
      userdepidCascaderSelected1: [],
      queryParams1:{
        monitorpointname:'',
        eventtype:901,
        eventcode:'',
        userdepsid:143,
        location:'',
        total:'',
        plannedwork:0,
        causeclassify:'',
        systemnameid:'',
        devicecode:'',
      },
      tableColumns:[
        {
          title: '公司/项目',
          dataIndex: 'monitorpointname',
          key: 'monitorpointname',
          ellipsis: true,
        },
        {
          title: '报警/事件',
          dataIndex: 'eventtype',
          key: 'eventtype',
          ellipsis: true,
          scopedSlots: { customRender: 'eventtype' },
        },
        {
          title: '报警/事件类型',
          dataIndex: 'eventname',
          key: 'eventname',
          ellipsis: true,
          scopedSlots: { customRender: 'eventname' },
        },
        {
          title: '发生重复报警/事件的编号/位置',
          dataIndex: 'location',
          key: 'location',
          ellipsis: true,
          scopedSlots: { customRender: 'location' },
        },
        {
          title: '数量',
          dataIndex: 'num',
          key: 'num',
          ellipsis: true,
          scopedSlots: { customRender: 'num' },
        },
      ],
      activeKey:"tab2",
      currentPage: 1,
      pagination: {
        total: 0,
        pageSize: 10,
      },
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    pageTitle() {
      return "异常情况分析报表"
    },
    breadcrumb() {
      const pages = [{name:"数据分析与报告",path:""}]
      pages.push({name:this.pageTitle,path:""})
      return pages
    },
  },
  watch:{
    deviceCascaderSelected(val) {
      if(val && val.length) {
        this.queryParams.devicecode = val[val.length-1];
        this.queryParams.eventcode='';
        this.queryParams1.devicecode = val[val.length-1];
      }else {
        this.queryParams.devicecode = '';
        this.queryParams1.devicecode = '';
      }
    },
    'queryParams.eventcode':function (){
      this.queryParams.devicecode = '';
      this.deviceCascaderSelected = [];
    },
    'queryParams1.eventcode':function (val){
      if(val!=="6"){
        this.queryParams1.devicecode=''
      }
    },
    'queryParams1.eventtype':function (val){
      if(val===902){
        this.Types=this.abnormalTypes
        this.queryParams1.eventcode=''
        this.queryParams1.causeclassify=''
        this.queryParams1.systemnameid=''
        this.queryParams1.devicecode=''
      }else{
        this.Types=this.alarmTypes
        this.queryParams1.eventcode=''
        this.queryParams1.causeclassify=''
        this.queryParams1.systemnameid=''
        this.queryParams1.devicecode=''
      }
    },
    'queryParams1.systemnameid':function (val){
      if(val!=="1"){
        this.queryParams1.causeclassify=''
      }
    },
    queryInstallDate(val) {
      let {start, end} = getTimeRange(val);
      this.queryParams.starttime = start;
      this.queryParams.endtime = end;
    },
    queryInstallDate1(val) {
      let {start, end} = getTimeRange(val);
      this.queryParams1.starttime = start;
      this.queryParams1.endtime = end;
    },
    userdepidCascaderSelected(val) {
      if(val && val.length) {
        this.queryParams.userdepsid = val[val.length-1];
      }else {
        this.queryParams.userdepsid = '';
      }
    },
    userdepidCascaderSelected1(val) {
      if(val && val.length) {
        this.queryParams1.userdepsid = val[val.length-1];
      }else {
        this.queryParams1.userdepsid = '';
      }
    },
  },
  created() {
    let logininfo = getCache('logininfo', true);
    if (logininfo) {
      this.deleteenable = logininfo.deleteenable;
      this.usertype=logininfo.usertype;
    }
    let dictionary = getCache('dictionary', true);
    if(dictionary) {
      this.bigInfluences = dictionary.bigInfluences;
      this.alarmTypes = dictionary.alarmTypes;
      this.alarmTypesMap = dictionary.alarmTypesMap;
      this.systemNames = dictionary.systemNames;
      this.systemNamesMap = dictionary.systemNamesMap;
      this.feedbackList = dictionary.feedbackList;
      this.abnormalTypes = dictionary.abnormalTypes;
      this.abnormalTypesMap = dictionary.abnormalTypesMap;
      this.equipmentTypes = dictionary.equipmentTypes;
      this.manageTypes = dictionary.manageTypes;
      this.recordTypes = dictionary.recordTypes;
      this.causeClassifyList = dictionary.causeClassifyList;
    }
    this.Types=this.alarmTypes
    let time = new Date();
    let monthNum = moment(time).format("YYYY-MM").slice(5);
    this.queryParams.starttime = moment(time).month(monthNum - 1).date(1).startOf("month").format("YYYYMMDD");
    this.queryParams.endtime = moment(time).month(monthNum - 1).date(1).endOf("month").format("YYYYMMDD");
    this.queryParams1.starttime = moment(time).month(monthNum - 1).date(1).startOf("month").format("YYYYMMDD");
    this.queryParams1.endtime = moment(time).month(monthNum - 1).date(1).endOf("month").format("YYYYMMDD");
    this.initDeptOptionsAll();
    this.getTableData(true,this.activeKey)
  },
  methods:{
    callback(key){
      if(key==="tab1"){
        this.queryParams.notice_type=1
        this.queryParams.content=""
        this.currentPage=1
        this.tableColumns=[
          {
            title: '公司/项目',
            dataIndex: 'monitorpointname',
            key: 'monitorpointname',
            ellipsis: true,
          },
          {
            title: '系统',
            dataIndex: 'systemname',
            key: 'systemname',
            ellipsis: true,
          },
          {
            title: '类型',
            dataIndex: 'eventname',
            key: 'eventname',
            ellipsis: true,
          },
          {
            title: '编号/位置',
            dataIndex: 'location',
            key: 'location',
            ellipsis: true,
          },
          {
            title: '到场反馈',
            dataIndex: 'feedback',
            key: 'feedback',
            ellipsis: true,
          },
          {
            title: '原因分类',
            dataIndex: 'causeclassify',
            key: 'causeclassify',
            ellipsis: true,
          },
          {
            title: '时段',
            dataIndex: 'timerange',
            key: 'timerange',
            ellipsis: true,
            width: 180,
            scopedSlots: { customRender: 'timerange' },
          },
          {
            title: '次数',
            dataIndex: 'total',
            key: 'total',
            align: 'center',
            width: 70,
            ellipsis: true,
            scopedSlots: { customRender: 'total' },
          },
          {
            title: '平均间隔',
            dataIndex: 'timeaverage',
            key: 'timeaverage',
            ellipsis: true,
            width: 160,
            scopedSlots: { customRender: 'timeaverage' },
          },
          {
            title: '最短间隔',
            dataIndex: 'minaverage',
            key: 'minaverage',
            ellipsis: true,
            width: 160,
            scopedSlots: { customRender: 'minaverage' },
          },
          {
            title: '操作',
            dataIndex: 'operation',
            key: 'operation',
            ellipsis: true,
            align:'center',
            scopedSlots: { customRender: 'operation' },
            width: 70
          },
        ]
        this.activeKey="tab1"
        this.getTableData(true,this.activeKey)
      }else if(key==="tab2"){
        this.queryParams.notice_type=1
        this.currentPage=1
        this.tableColumns=[
          {
            title: '公司/项目',
            dataIndex: 'monitorpointname',
            key: 'monitorpointname',
            ellipsis: true,
          },
          {
            title: '报警/事件',
            dataIndex: 'eventtype',
            key: 'eventtype',
            ellipsis: true,
            scopedSlots: { customRender: 'eventtype' },
          },
          {
            title: '报警/事件类型',
            dataIndex: 'eventname',
            key: 'eventname',
            ellipsis: true,
            scopedSlots: { customRender: 'eventname' },
          },
          {
            title: '发生重复报警/事件的编号/位置',
            dataIndex: 'location',
            key: 'location',
            ellipsis: true,
            scopedSlots: { customRender: 'location' },
          },
          {
            title: '数量',
            dataIndex: 'num',
            key: 'num',
            ellipsis: true,
            scopedSlots: { customRender: 'num' },
          },
        ]
        this.activeKey="tab2"
        this.getTableData(true,this.activeKey)
      }
    },
    pageChange(page, size) {
      if(this.activeKey==="tab2"){
        this.getTableData("","tab2");
      }else{
        this.getTableData("","tab1");
      }
    },
    onShowSizeChange(page, size) {
      this.pagination.pageSize = size;
      this.getTableData(true,this.activeKey);
    },
    getTableData(firstPage,type) {
      if(firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      if(type==="tab1"){
        let params = {
          ...this.queryParams,
          history:true,
          batterycar:true,
          pageno: this.currentPage,
          pagesize: this.pagination.pageSize
        };
        if(this.queryParams.devicecode){
          this.tableColumns[2]={
            title: '类型',
            dataIndex: 'devicename',
            key: 'devicename',
            ellipsis: true,
          }
        }else{
          this.tableColumns[2]={
            title: '类型',
            dataIndex: 'eventname',
            key: 'eventname',
            ellipsis: true,
          }
        }
        getAbnormalAnalysisReport(params).then(res => {
          this.tableLoading = false;
          if(res && res.returncode == '0') {
            this.tableData = res.item;
            this.pagination.total = res.count;
          }
        }).catch(err => {
          this.tableLoading = false;
        })
      }else if(type==="tab2"){
        let params = {
          ...this.queryParams1,
          history:true,
          batterycar:true,
          pageno: this.currentPage,
          pagesize: this.pagination.pageSize
        };
        findAbnormalAnalysisReportGroupLocation(params).then(res => {
          this.tableLoading = false;
          if(res && res.returncode === '0') {
            this.tableData1 = res.item;
            this.pagination.total = res.count;
          }
        }).catch(err => {
          this.tableLoading = false;
        })
      }
    },
    resetQueryParams() {
      if(this.activeKey==="tab1"){
        this.deviceCascaderSelected = [];
        this.queryInstallDate = [];
        this.eventcode='';
        this.userdepidCascaderSelected=[]
        this.queryParams= {
          monitorpointname:'',
          systemnameid:'',
          reporttype:1,
          devicecode:'',
          starttime:'',
          endtime:'',
          eventcode:'',
          location:'',
          feedback:'',
          plannedwork:'',
          causeclassify:'',
          userdepsid:143,
          querytype:1,
        }
      }else if(this.activeKey==="tab2"){
        this.queryInstallDate1 = [];
        this.userdepidCascaderSelected1=[]
        this.queryParams1= {
          monitorpointname:'',
          eventtype:this.queryParams1.eventtype,
          eventcode:'',
          starttime:'',
          endtime:'',
          location:'',
          total:'',
          plannedwork:'',
          causeclassify:'',
          systemnameid:'',
          devicecode:'',
          userdepsid:143,
        }
      }

    },
    operationClick(record) {
      this.modalDetail = this.queryParams
      if(this.queryParams.querytype==1){
        this.modalDetail.monitorpointname=record.monitorpointname
        this.modalDetail.userdepsid=""
      }else if(!this.queryParams.userdepsid){
        this.modalDetail.userdepsid=143
      }
      this.modalDetail.type="normal"
      this.detailVisible = true
    },
    customRow(record, index){
      return {
        on: { // 事件
          click: () => {
            this.modalDetail = this.queryParams
            if(this.queryParams.querytype==1){
              this.modalDetail.monitorpointname=record.monitorpointname
              this.modalDetail.userdepsid=""
            }else if(!this.queryParams.userdepsid){
              this.modalDetail.userdepsid=143
            }
            this.modalDetail.type="normal"
            this.detailVisible = true
          },
        },
      }
    },
    checkTotalRecordDetail(record){
      this.modalDetail = this.queryParams
      if(this.queryParams.querytype==1){
        this.modalDetail.monitorpointname=record.monitorpointname
        this.modalDetail.userdepsid=""
      }else if(!this.queryParams.userdepsid){
        this.modalDetail.userdepsid=143
        this.detailVisible = true
      }
      this.modalDetail.type="total"
      this.detailVisible = true
    },
    checkMinAverageDetail(record){
      this.modalDetail = this.queryParams
      if(this.queryParams.querytype==1){
        this.modalDetail.monitorpointname=record.monitorpointname
        this.modalDetail.userdepsid=""
      }else if(!this.queryParams.userdepsid){
        this.modalDetail.userdepsid=143
        this.detailVisible = true
      }
      this.modalDetail.type="minaverage"
      this.detailVisible = true
    },
    checkLocationRecordDetail(record){
      this.modalDetail = this.queryParams1
      this.modalDetail.monitorpointnum=record.monitorpointnum
      this.modalDetail.monitorpointname=record.monitorpointname
      this.modalDetail.eventtype=record.eventtype
      this.modalDetail.eventcode=record.eventcode
      this.modalDetail.location=record.location
      this.modalDetail.type="location"
      this.detailVisible = true
    },
    exportModal(){
      this.$confirm('你确认要按照所选筛选条件导出文件吗？',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.exportEventRecord()
      }).catch(()=>{
      });
    },
    exportEventRecord(){
      let params = {
        ...this.queryParams,
        history:true,
        batterycar:true,
      }
      this.showLoading();
      exportEventRecordReportExcel(params).then((res)=> {
        this.hideLoading();
        this.$message.success("导出成功")
      }).catch(()=>{
        this.hideLoading();
      })
    },
    exportReportModal(){
      this.$confirm('你确认要按照所选筛选条件导出文件吗？',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.exportAbnormalAnalysisReport()
      }).catch(()=>{
      });
    },
    exportAbnormalAnalysisReport(){
      let params = {
        ...this.queryParams1,
        history:true,
        batterycar:true,
      }
      this.showLoading();
      exportAbnormalAnalysisReportGroupLocation(params).then((res)=> {
        this.hideLoading();
        this.$message.success("导出成功")
      }).catch(()=>{
        this.hideLoading();
      })
    }
  },
}
</script>
<style scoped>

</style>